import { useCallback, useEffect, useRef, useState } from 'react';
import { NEXT_EVENT } from '../../js/endpoints.js';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { request } from '../../js/APIUtils.js';
import { strings } from '../../components/Language.js';
import { formatDate } from '../../js/Common.js';

export default function Home() {
  const [year] = useState(new Date().getFullYear());
  const [event, setEvent] = useState({})
  const [remainingDays, setRemainingDays] = useState(null);
  const [remainingHours, setRemainingHours] = useState(null);
  const [remainingMinutes, setRemainingMinutes] = useState(null);
  const [remainingSeconds, setRemainingSeconds] = useState(null);
  const [selectedDay, setSelectedDay] = useState(0);
  const [ticketPrice] = useState('');

  const calculateTime = useCallback(() => {
    if (Object.keys(event).length > 0) {
      const eventDate = new Date(event.days[0].date);
      const time = eventDate.getTime() - new Date().getTime();

      let seconds = time / 1000;
      let minutes = seconds / 60;
      seconds = parseInt(seconds % 60);
      let hours = minutes / 60;
      minutes = parseInt(minutes % 60);
      const days = parseInt(hours / 24);
      hours = parseInt(hours % 24);

      setRemainingDays(days > 0 ? days : 0)
      setRemainingHours(hours > 0 ? hours : 0);
      setRemainingMinutes(minutes > 0 ? minutes : 0);
      setRemainingSeconds(seconds > 0 ? seconds : 0);
    }
  }, [event, setRemainingDays, setRemainingHours, setRemainingMinutes, setRemainingSeconds]);

  const mounted = useRef();
  useEffect(() => {
    const checkWindowSize = () => {
      let i = 0;
      for (let bar of document.querySelectorAll('.scheduleDay')) {
        if (window.innerWidth >= 540) {
          bar.style.left = (110 * i) + 'px';
        } else {
          bar.style.left = (60 * i) + 'px';
        }
        ++i;
      }
    };

    if (!mounted.current) {

      request(NEXT_EVENT).then(jsonObject => {
        if (jsonObject.httpStatus === 200) {
          setEvent(jsonObject.event);
          document.cookie = '_eId=' + jsonObject.event.id;
        }
      })

      mounted.current = true;
    } else if (JSON.stringify(event) !== mounted.event) {
      calculateTime();
      checkWindowSize();

      mounted.event = event;
    }
  }, [setEvent, calculateTime, event]);

  setInterval(() => calculateTime(), 1000);

  function formatDates(pattern) {
    let dateText = '';
    if (Object.keys(event).length > 0) {
      if (event.days.length === 1 || pattern.indexOf('D') < 0) {
        dateText = formatDate(event.days[0].date, pattern);
      } else {
        for (let index = 0; index < event.days.length; ++index) {
          const date = new Date(event.days[index].date);
          if (index > 0 && index < event.days.length - 1) {
            dateText += ', ' + date.getDate();
          } else if (index === event.days.length - 1) {
            dateText += ' ' + strings('common.andPrep') + ' ' + formatDate(event.days[index].date, pattern);
          } else {
            dateText += date.getDate();
          }
        }
      }
    }

    return dateText;
  }

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/public/header-block.css" />
          <link rel="stylesheet" type="text/css" href="/css/public/why-visit.css" />
          <link rel="stylesheet" type="text/css" href="/css/public/schedule.css" />
          <link rel="stylesheet" type="text/css" href="/css/public/partners.css" />
          <link rel="stylesheet" type="text/css" href="/css/public/tickets.css" />
          <link rel="stylesheet" type="text/css" href="/css/public/contact.css" />
        </Helmet>

        <div id="headBlock" className="titleBlock">
          <div className="titleContainer">
            <h1 className="mainTitle">{event.name}</h1>
            <p className="subtitle" dangerouslySetInnerHTML={{ __html: strings('home.subtitle') }} />
            <div className="timerContainer">
              <div className="timerWrap">
                <div className="counter-item">
                  <i className="day-circle icon icon-ring-1Asset-1" />
                  <span className="day-count days countdown-number">{remainingDays}</span>
                  <div className="smalltext">{remainingDays === 1 ? strings('home.day') : strings('home.days')}</div>
                </div>
                <div className="counter-item">
                  <i className="hr-circle icon icon-ring-4Asset-3" />
                  <span className="hr-count hours countdown-number">{remainingHours}</span>
                  <div className="smalltext">{remainingHours === 1 ? strings('home.hour') : strings('home.hours')}</div>
                </div>
                <div className="counter-item">
                  <i className="min-circle icon icon-ring-3Asset-2" />
                  <span className="min-count minutes countdown-number">{remainingMinutes}</span>
                  <div className="smalltext">{remainingMinutes === 1 ? strings('home.minute') : strings('home.minutes')}</div>
                </div>
                <div className="counter-item">
                  <i className="sec-circle icon icon-ring-4Asset-3" />
                  <span className="sec-count seconds countdown-number">{remainingSeconds}</span>
                  <div className="smalltext">{remainingSeconds === 1 ? strings('home.second') : strings('home.seconds')}</div>
                </div>
              </div>
            </div>
            <div className="ticketsLinkContainer">
              <a href="billeterie" className="ticketsLink">
                <span>{strings('menu.tickets')}</span>
              </a>
            </div>
            <p className="date"><strong className="dateText">{formatDates('D MMMM YYYY')}</strong> - <span className="place">{event.location}</span></p>
          </div>
        </div>

        <div id="info" className="row section">
          <div className="sectionContainer">
            <div className="sectionRow">
              <div className="col-lg-4">
                <div className="why-visitTitleSection">
                  <h2 className="why-visitTitle">
                    <span className="why-visitSub-title uppercase">{strings('home.whyVisit')}</span>
                    {strings('home.whyVist2')}
                  </h2>
                  <span className="why-visitSvg">
                    <svg width="71px" height="11px">
                      <path fillRule="evenodd"
                        d="M59.669,10.710 L49.164,3.306 L39.428,10.681 L29.714,3.322 L20.006,10.682 L10.295,3.322 L1.185,10.228 L-0.010,8.578 L10.295,0.765 L20.006,8.125 L29.714,0.765 L39.428,8.125 L49.122,0.781 L59.680,8.223 L69.858,1.192 L70.982,2.895 L59.669,10.710 Z">
                      </path>
                    </svg>
                  </span>
                </div>
                <p className="why-visitText">
                  {strings('home.whyVisitDesc')}
                </p>
              </div>
              <div className="col-lg-8">
                <div className="sectionRow">
                  <div className="col-lg-6">
                    <div className="why-visitBox">
                      <i className="icon icon-light-bulb-1 why-visitBoxIcon" />
                      <h3 className="why-visitBoxTitle">{strings('home.whyVisit1Title')}</h3>
                      <p className="why-visitBoxText">
                        {strings('home.whyVisit1Desc')}
                      </p>
                      <span className="why-visitBoxNumber">1</span>
                    </div>

                    <div className="why-visitBox">
                      <i className="icon icon-speaker why-visitBoxIcon" />
                      <h3 className="why-visitBoxTitle">{strings('home.whyVisit2Title')}</h3>
                      <p className="why-visitBoxText">
                        {strings('home.whyVisit2Desc')}
                      </p>
                      <span className="why-visitBoxNumber">2</span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="why-visitBox">
                      <i className="icon icon-power-switch why-visitBoxIcon" />
                      <h3 className="why-visitBoxTitle">{strings('home.whyVisit3Title')}</h3>
                      <p className="why-visitBoxText">
                        {strings('home.whyVisit3Desc', { year: year })}
                      </p>
                      <span className="why-visitBoxNumber">3</span>
                    </div>

                    <div className="why-visitBox">
                      <i className="icon icon-fun why-visitBoxIcon" />
                      <h3 className="why-visitBoxTitle">{strings('home.whyVisit4Title')}</h3>
                      <p className="why-visitBoxText">
                        {strings('home.whyVisit4Desc')}
                      </p>
                      <span className="why-visitBoxNumber">4</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="programme" className="row section scheduleSection" style={{ display: 'none' }}>
          <div className="sectionContainer">
            <div className="sectionRow">
              <div className="col-lg-6">
                <h2 className="scheduleTitle">
                  <span className="scheduleSubTitle">{strings('home.scheduleTitle')} {formatDates('YYYY')}</span>
                  {strings('home.scheduleSubtitle')}

                  <p className="scheduleSvg">
                    <svg width="71px" height="11px">
                      <path fillRule="evenodd"
                        d="M59.669,10.710 L49.164,3.306 L39.428,10.681 L29.714,3.322 L20.006,10.682 L10.295,3.322 L1.185,10.228 L-0.010,8.578 L10.295,0.765 L20.006,8.125 L29.714,0.765 L39.428,8.125 L49.122,0.781 L59.680,8.223 L69.858,1.192 L70.982,2.895 L59.669,10.710 Z">
                      </path>
                    </svg>
                  </p>
                </h2>

                <p className="scheduleText">
                  {strings('home.scheduleText', { year: year })}
                </p>
              </div>
              <div className="col-lg-6">
                <div className="scheduleDaysContainer">
                  {event.days?.map((eventDay, index) => {
                    const dateStr = formatDate(eventDay.date, 'YYYY-MM-DD')
                    return <button key={index} onClick={() => setSelectedDay(index)} className={selectedDay === index ? 'scheduleDay active' : 'scheduleDay'} date={dateStr}><h3>{formatDate(eventDay.date, 'D MMMM')}</h3><span>{formatDate(eventDay.date, 'dddd')}</span></button>
                  })}
                </div>
              </div>
            </div>

            <div className="sectionRow">
              <div id="dailySchedule" className="col-lg-12">
                {event.days?.map((eventDay, index) =>
                  eventDay.schedules?.map((time, index2) =>
                    <div key={index + '_' + index2} className="scheduleTime" style={selectedDay === index ? null : { display: 'none' }}>
                      <div className="scheduleTimeSlotTime">
                        <span>{formatDate(time.start, 'HH:mm')} - {formatDate(time.end, 'HH:mm')}</span>
                      </div>
                      <div className="scheduleTimeSlotInfo">
                        <div className="scheduleTimeSlotInfo-content">
                          <h3 className="schedule-slot-title" dangerouslySetInnerHTML={{ __html: time.title }} />
                          <p className="schedule-slot-text" dangerouslySetInnerHTML={{ __html: time.text ? time.text.replaceAll('{httpdHostname}', process.env.REACT_APP_HTTPD_HOSTNAME) : null }} />
                          <div className="single-speaker" />
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>

        <div id="partners" className="row section partnersSection">
          <div className="sectionContainer">
            <h2 className="scheduleTitle partnersTitle uppercase centered">
              {strings('home.partnersTitle')}
            </h2>
            <div className="centered">
              <img width="220" height="150" alt='' src={process.env.REACT_APP_HTTPD_HOSTNAME + 'assets/immotech/partners/ministere.jpg'} />
            </div>

            <div className="sectionRow">
              <div className="col-lg-25 centered">
                <h2 className="scheduleTitle partnersSubTitle uppercase centered">
                  {strings('home.partnersSection1Title')}
                </h2>

                <img width="80" className='partnerImg' alt='' src={process.env.REACT_APP_HTTPD_HOSTNAME + 'assets/immotech/partners/lafarge.png'} />
                <img width="120" className='partnerImg' alt='' src={process.env.REACT_APP_HTTPD_HOSTNAME + 'assets/immotech/partners/welearn.png'} />
              </div>
              <div className="col-lg-25 centered">
                <h2 className="scheduleTitle partnersSubTitle uppercase centered">
                  {strings('home.partnersSection2Title')}
                </h2>

                <img width="60" alt='' src={process.env.REACT_APP_HTTPD_HOSTNAME + 'assets/immotech/partners/orange.png'} />
              </div>
              <div className="col-lg-25 centered">
                <h2 className="scheduleTitle partnersSubTitle uppercase centered">
                  {strings('home.partnersSection3Title')}
                </h2>

                <img width="140" alt='' src={process.env.REACT_APP_HTTPD_HOSTNAME + 'assets/immotech/partners/mfadel.png'} />
              </div>
              <div className="col-lg-25 centered">
                <h2 className="scheduleTitle partnersSubTitle uppercase centered">
                  {strings('home.partnersSection4Title')}
                </h2>

                <img width="140" alt='' src={process.env.REACT_APP_HTTPD_HOSTNAME + 'assets/immotech/partners/smart-expos.png'} />
              </div>
            </div>

            <div className="sectionRow">
              <div className="col-lg-33 centered">
                <h2 className="scheduleTitle partnersSubTitle uppercase centered">
                  {strings('home.partnersSection5Title')}
                </h2>

                <img width="250" alt='' src={process.env.REACT_APP_HTTPD_HOSTNAME + 'assets/immotech/partners/partner-institutionals.png'} />
              </div>
              <div className="col-lg-33 centered">
                <h2 className="scheduleTitle partnersSubTitle uppercase centered">
                  {strings('home.partnersSection6Title')}
                </h2>

                <img width="360" alt='' src={process.env.REACT_APP_HTTPD_HOSTNAME + 'assets/immotech/partners/partners-media.png'} />
              </div>
              <div className="col-lg-33 centered">
                <h2 className="scheduleTitle partnersSubTitle uppercase centered">
                  {strings('home.partnersSection7Title')}
                </h2>

                <img width="140" alt='' src={process.env.REACT_APP_HTTPD_HOSTNAME + 'assets/immotech/partners/partners-africa-strategic.png'} />
              </div>
            </div>

            <h2 className="scheduleTitle partnersSubTitle uppercase centered">
              {strings('home.partnersOrganizers')}
            </h2>
            <div className="centered">
              <img className='partnerImg' width="240" alt='' src={process.env.REACT_APP_HTTPD_HOSTNAME + 'assets/immotech/partners/mubawab.png'} />
              <img className='partnerImg' width="120" alt='' src={process.env.REACT_APP_HTTPD_HOSTNAME + 'assets/immotech/partners/rent.png'} />
            </div>
          </div>
        </div>

        <div id="tickets" className="row section ticketsSection">
          <div className="ticketsOverlay"></div>
          <div className="sectionContainer ticketsContainer">
            <div className="ticketsTitleContainer">
              <h2 className="ticketsTitle">
                <span className="ticketsSubTitle">{strings('home.tikcketsTitle')}</span>
                {strings('home.ticketsSubtitle')}
              </h2>
              <div className="ticketsTitleDesc">
                <p><span>{strings('home.ticketsDesc')} <span className="dateText">{formatDates('D MMMM YYYY')}</span>.</span></p>
              </div>

              <p className="ticketsSvg">
                <svg width="71px" height="11px">
                  <path fillRule="evenodd"
                    d="M59.669,10.710 L49.164,3.306 L39.428,10.681 L29.714,3.322 L20.006,10.682 L10.295,3.322 L1.185,10.228 L-0.010,8.578 L10.295,0.765 L20.006,8.125 L29.714,0.765 L39.428,8.125 L49.122,0.781 L59.680,8.223 L69.858,1.192 L70.982,2.895 L59.669,10.710 Z">
                  </path>
                </svg>
              </p>
            </div>

            <div className="ticketsInfoWrap">
              <div className="ticketsInfoContainer">
                <i className="icon icon-home"></i>
                <h2 className="ticketName">{strings('home.entrance')}</h2>
                <h3 className="ticketPrice" style={{ display: 'none' }}>{ticketPrice}</h3>
                <p>{strings('home.entranceDesc1')}</p>
                <p>{strings('home.entranceDesc2')}</p>
                <p>{strings('home.entranceDesc3')}</p>
                <p>{strings('home.entranceDesc4')}</p>
                <p>{strings('home.entranceDesc5')}</p>
                <p>{strings('home.entranceDesc6')}</p>
                <a target="_self" rel="nofollow" href="billeterie" className="ticketsBtn">{strings('home.subscribe')}</a>
              </div>
            </div>
          </div>
        </div>

        <div id="contact" className="contactSection">
          <div className="contactOverlay"></div>
          <div className="contactWrapper">
            <div className="sectionRow">
              <div className="col-lg-5 centered">
                <div className="contactTitleContainer">
                  <h2 className="contactTitle">
                    {strings('home.contactTitle')}
                  </h2>

                  <p className="contactSvg">
                    <svg width="71px" height="11px">
                      <path fillRule="evenodd"
                        d="M59.669,10.710 L49.164,3.306 L39.428,10.681 L29.714,3.322 L20.006,10.682 L10.295,3.322 L1.185,10.228 L-0.010,8.578 L10.295,0.765 L20.006,8.125 L29.714,0.765 L39.428,8.125 L49.122,0.781 L59.680,8.223 L69.858,1.192 L70.982,2.895 L59.669,10.710 Z">
                      </path>
                    </svg>
                  </p>
                </div>

                <div className="tabWrapper">
                  {strings('home.address')}
                </div>
                <div className="tabContent">
                  <p className="place">{event.location}</p>
                  <p className="address">{event.address}</p>

                  <h3>{strings('home.ticketsInfo')}</h3>
                  <p><strong>{strings('home.contact')}: </strong><span className="contactPhone">{event.phone}</span></p>
                  <p><strong>{strings('common.email')}:&nbsp;</strong><span className="contactEmail">{event.email}</span></p>
                </div>
              </div>
              <div className="col-lg-7">
                <iframe title='map'
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1771.5664088753122!2d-7.707597328906906!3d33.571928978539546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7d5bcf00b876b%3A0x421f958beb7c1b3e!2sGarden%20Event!5e0!3m2!1sca!2ses!4v1688542224387!5m2!1sca!2ses"
                  className="contactMap" allowFullScreen="" loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}