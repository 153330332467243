import { strings } from "./Language";

export default function UserCard({ user, red }) {
  return (
    <div className={'card' + (red ? ' borderRed' : '')}>
      <div className="card-body">
        <div className="centered">
          {user.vip ?
            <h1 className={'blue' + (user.vip ? ' margTop10' : '')}>{strings('visited.vip')}</h1>
            :
            null
          }
          <h4 className={(red ? 'red' : '') + (user.vip ? ' margTop10' : '')}>{user.name} {user.lastName}</h4>
          <p className="subtitle">{user.business}</p>
          <p className="subtitle margBot20">{user.profesion}</p>
        </div>

        <div className="row">
          <div className="col-sm-3 label">
            {strings('common.email')}
          </div>
          <div className="col-sm-9 text-secondary">
            {user.email}
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3 label">
            {strings('common.phone')}
          </div>
          <div className="col-sm-9 text-secondary">
            {user.phone}
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3 label">
            {strings('common.country')}
          </div>
          <div className="col-sm-9 text-secondary">
            {user.country}
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3 label">
            {strings('common.sector')}
          </div>
          <div className="col-sm-9 text-secondary">
            {user.businessSectorName}
          </div>
        </div>
        {/* <hr />
        <div className="row">
          <div className="col-sm-3 label">
            {strings('common.speciality')}
          </div>
          <div className="col-sm-9 text-secondary">
            {user.businessSpecialityNames}
          </div>
        </div> */}
        {user.workshops?.length > 0 ?
          [<hr key='separator' />,
          <div key='workshops' className="row">
            <div className="col-sm-3 label">
              {strings('menu.workshops')}
            </div>
            <div className="col-sm-9 text-secondary">
              <ul>
                {user.workshops.map((workshop, index) =>
                  <li key={index}>{workshop.name}</li>
                )}
              </ul>
            </div>
          </div>]
          :
          null}
      </div>
    </div>
  );
}

