import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const LEFT = 'ArrowLeft'
const RIGHT = 'ArrowRight'
const ESCAPE = 'Escape'

export default function ImageSlider({ images, index, onClose }) {
    const containerRef = useRef(null);
    const [currentIndex, _setCurrentIndex] = useState(null);
    const touchStartX = useRef(null);
    const touchEndX = useRef(null);

    const mounted = useRef();
    const setCurrentIndex = useCallback(data => {
        mounted.currentIndex = data;
        _setCurrentIndex(data);
    }, [_setCurrentIndex]);

    const changeImage = useCallback(async (index) => {
        const imgIndex = index < 0 ? images.length - 1 : index > images.length - 1 ? 0 : index;
        const prevIndex = imgIndex - 1 < 0 ? images.length - 1 : imgIndex - 1;
        if (document.querySelector('.image-container').getElementsByClassName('slide-image')[prevIndex].src === '') {
            document.querySelector('.image-container').getElementsByClassName('slide-image')[prevIndex].src = document.querySelector('.image-container').getElementsByClassName('slide-image')[prevIndex].getAttribute('data-src');
            document.querySelector('.image-container').getElementsByClassName('slide-image')[prevIndex].removeAttribute('data-src');
        }
        if (document.querySelector('.image-container').getElementsByClassName('slide-image')[imgIndex].src === '') {
            document.querySelector('.image-container').getElementsByClassName('slide-image')[imgIndex].src = document.querySelector('.image-container').getElementsByClassName('slide-image')[index].getAttribute('data-src');
            document.querySelector('.image-container').getElementsByClassName('slide-image')[imgIndex].removeAttribute('data-src');
        }
        const nextIndex = imgIndex + 1 > images.length - 1 ? 0 : imgIndex + 1;
        if (document.querySelector('.image-container').getElementsByClassName('slide-image')[nextIndex].src === '') {
            document.querySelector('.image-container').getElementsByClassName('slide-image')[nextIndex].src = document.querySelector('.image-container').getElementsByClassName('slide-image')[nextIndex].getAttribute('data-src');
            document.querySelector('.image-container').getElementsByClassName('slide-image')[nextIndex].removeAttribute('data-src');
        }

        document.querySelector('.image-container').style.transform = 'translateX(' + calculateTransaltion(index) + ')';
        if (document.querySelector('.image-container').getAttribute('loaded') == null) {
            document.querySelector('.image-container').setAttribute('loaded', 'true');
        }
        setCurrentIndex(index);
    }, [images, setCurrentIndex]);

    const handleKeyPress = useCallback((event) => {
        if (LEFT === event.key) {
            changeImage(mounted.currentIndex - 1);
        } else if (RIGHT === event.key) {
            changeImage(mounted.currentIndex + 1);
        } else if (ESCAPE === event.key) {
            onClose();
        }
    }, [changeImage, onClose]);

    useEffect(() => {
        const handleTransitionEnd = () => {
            if (mounted.currentIndex < 0 || mounted.currentIndex >= images.length) {
                document.querySelector('.image-container').classList.add('no-transition');

                let newIndex;
                if (mounted.currentIndex >= images.length) {
                    newIndex = 0;
                } else {
                    newIndex = images.length - 1;
                }
                changeImage(newIndex);

                mounted.settingPosition = true;
            }
        }

        let listenerAdded = false;
        if (!mounted.current) {
            const handleKeyDown = (event) => {
                handleKeyPress(event);
            };
            window.addEventListener('keydown', handleKeyDown);

            document.querySelector('.image-container').addEventListener('transitionend', handleTransitionEnd);
            mounted.current = true;

            listenerAdded = true;
        }
        if (index !== mounted.loadedIndex) {
            mounted.loadedIndex = index;
            setCurrentIndex(index);
            changeImage(index);
        }
        if (mounted.settingPosition) {
            document.querySelector('.image-container').classList.remove('no-transition');

            mounted.settingPosition = false;
        }
        if (listenerAdded) {
            return () => {
                document.removeEventListener('keydown', useCallback);
            }
        }
    }, [changeImage, setCurrentIndex, currentIndex, handleKeyPress, images, index]);

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        touchEndX.current = e.touches[0].clientX;

        if (touchStartX.current && touchEndX.current) {
            const deltaX = touchEndX.current - touchStartX.current;
            const container = containerRef.current;
            if (container) {
                const newOffset = (Number(calculateTransaltion(currentIndex).replace('%', '')) / 100 * window.innerWidth) + deltaX;
                document.querySelector('.image-container').style.transform = 'translateX(' + newOffset + 'px)';
            }
        }
    };

    const handleTouchEnd = () => {
        if (touchStartX.current && touchEndX.current) {
            const deltaX = touchEndX.current - touchStartX.current;
            if (deltaX > 0 && Math.abs(deltaX) >= window.innerWidth / 3) {
                changeImage(currentIndex - 1);
            } else if (deltaX < 0 && Math.abs(deltaX) >= window.innerWidth / 3) {
                changeImage(currentIndex + 1);
            } else {
                document.querySelector('.image-container').style.transform = 'translateX(' + calculateTransaltion(currentIndex) + ')';
            }
        }
        touchStartX.current = null;
        touchEndX.current = null;
    };

    const handleMouseDown = (e) => {
        touchStartX.current = e.clientX;
    };

    const handleMouseMove = (e) => {
        if (touchStartX.current !== null) {
            touchEndX.current = e.clientX;
        }

        if (touchStartX.current && touchEndX.current) {
            const deltaX = touchEndX.current - touchStartX.current;
            const container = containerRef.current;
            if (container) {
                const newOffset = (Number(calculateTransaltion(currentIndex).replace('%', '')) / 100 * window.innerWidth) + deltaX;
                document.querySelector('.image-container').style.transform = 'translateX(' + newOffset + 'px)';
            }
        }
    };

    return (
        <HelmetProvider>
            <Helmet>
                <link rel="stylesheet" type="text/css" href="/css/image-slider.css" />
            </Helmet>
            <div id='preview'>

                <div className='previewTopBar'>
                    <button className='previewBtn' onClick={() => onClose()}>
                        <i className='icon-cross' />
                    </button>
                </div>

                <button className='prevBtn previewBtn' onClick={() => changeImage(currentIndex - 1)}>
                    <i className='icon-arrow-left' />
                </button>
                <div className='image-slider' onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}
                    onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleTouchEnd}>
                    <div className='image-container' ref={containerRef}>
                        {images.length > 1 ? <img className='last-img' alt='' src={images[images.length - 1]} /> : null}
                        {images.map((image, index) => <img key={index} className='slide-image' alt='' data-src={image} />)}
                        {images.length > 1 ? <img className='first-img' alt='' src={images[0]} /> : null}
                    </div>
                </div>
                <button className='nextBtn previewBtn' onClick={() => changeImage(currentIndex + 1)}>
                    <i className='icon-arrow-right' />
                </button>
            </div>
        </HelmetProvider>
    );
}

const calculateTransaltion = (index) => {
    const position = index * 100;
    return (-position - 100) + '%';
}