import { strings } from "./Language";

function DisplayPrice({ price, currency, className }) {
  const priceStr = price != null ? price : ''
  const currencyStr = currency != null ? strings('common.' + currency) : ''

  return (
    <span className={className}>{priceStr} {currencyStr}</span>
  );
}

export default DisplayPrice;
