import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CHECK_PROMOTION_CODE, GET_BILLETERIE_OPTIONS, GET_RATE_OPTIONS, REGISTER_PAYMENT, getEventId } from '../../js/endpoints';
import Counter from '../../components/inputs/Counter';
import { request } from '../../js/APIUtils';
import { strings } from '../../components/Language';
import DisplayPrice from '../../components/DisplayPrice';
import moment from 'moment';
import Loading from '../../components/Loading';
import EntranceForm, { EMPTY_TICKET, isValidEntrance } from '../../components/EntranceForm';
import TermsAndConditions from '../../components/TermsAndConditions';

const MAX_TICKETS = 10;

let timer;

export default function Billeterie() {
  const [rates, setRates] = useState([]);
  const [ticketOptions, setTicketOptions] = useState([]);
  const [sectorOptions, setSectorOptions] = useState([]);
  // const [specialityOptions, setSpecialityOptions] = useState([]);

  const [showCounter, setShowCounter] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [date, setDate] = useState('');
  const [selectedTicket, setSelectedTicket] = useState({});
  const [people, setPeople] = useState(1);
  const [totalAmount, setTotalAmount] = useState(null);
  const [originalAmount, setOriginalAmount] = useState(0);
  const [currency, setCurrency] = useState();
  const [promotionCode, setPromotionCode] = useState('');
  const [promotionCodeInputError, setPromotionCodeInputError] = useState();
  const [promotionCodeError, setPromotionCodeError] = useState();
  const [activePromotion, setActivePromotion] = useState();
  const [minTickets, setMinTickets] = useState(1);

  const [tickets, setTickets] = useState([{ ...EMPTY_TICKET }]);
  const [termsAndConditionsError, setTermsAndConditionsError] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const calculateTotalAmount = useCallback(() => {
    let selectedTicket = ticketOptions.filter(t => t.people === people);
    if (selectedTicket.length === 0) {
      selectedTicket = ticketOptions[ticketOptions.length - 1];
    } else {
      selectedTicket = selectedTicket[0];
    }

    const price = selectedTicket.price;

    if (activePromotion != null && activePromotion.price != null) {
      if (activePromotion.people == null || activePromotion.people >= people) {
        setTotalAmount(activePromotion.price * people);
      } else {
        const remainingPeople = people - activePromotion.people;
        setTotalAmount((activePromotion.price * activePromotion.people) + (price * remainingPeople));
      }
    }

    setOriginalAmount(price * people);
  }, [people, ticketOptions, activePromotion, setTotalAmount, setOriginalAmount]);

  const changePeople = useCallback((newPeople) => {
    setPeople(newPeople);
    if (tickets.length > newPeople) {
      setTickets(tickets.slice(0, newPeople));
    } else {
      const auxTickets = [...tickets];
      while (auxTickets.length < newPeople) {
        auxTickets.push({ ...EMPTY_TICKET });
      }
      setTickets(auxTickets);
    }
    calculateTotalAmount();

    if (ticketOptions[ticketOptions.length - 1].people <= newPeople) {
      setShowCounter(true);
    } else {
      setShowCounter(false);
    }
  }, [setPeople, setShowCounter, tickets, setTickets, ticketOptions, calculateTotalAmount]);

  const changeSelectedTicket = useCallback((newTicket) => {
    setSelectedTicket(newTicket);
    changePeople(newTicket.people);
  }, [setSelectedTicket, changePeople]);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      getEventId().then(eventId => {
        request(GET_BILLETERIE_OPTIONS, { eventId: eventId }).then(async (jsonObject) => {
          if (jsonObject.priceList != null && jsonObject.priceList.length > 0) {
            setTicketOptions(jsonObject.priceList);
            setMinTickets(jsonObject.priceList.length);
            setSelectedTicket(jsonObject.priceList[0]);
            setCurrency(jsonObject.priceList[0].currency);

            setSectorOptions(jsonObject.sectorList);
            // setSpecialityOptions(jsonObject.specialityList);

            let dateText = '';
            const eventDate = new Date(jsonObject.days[0]);
            const pattern = 'D MMMM';
            if (jsonObject.days.length === 1) {
              dateText = moment(eventDate).format(pattern);
            } else {
              for (let index = 0; index < jsonObject.days.length; ++index) {
                const date = new Date(jsonObject.days[index]);
                if (index > 0 && index < jsonObject.days.length - 1) {
                  dateText += ', ' + date.getDate();
                } else if (index === jsonObject.days.length - 1) {
                  dateText += ' ' + strings('common.andPrep') + ' ' + moment(date).format(pattern);
                } else {
                  dateText += date.getDate();
                }
              }
            }
            setDate(dateText);
          }
        });

        request(GET_RATE_OPTIONS, { eventId: eventId }).then(async (jsonObject) => {
          setRates(jsonObject.rates);
        });
      });

      mounted.current = true;
      mounted.previousPeople = people;
    } else if (ticketOptions.length > 0 && JSON.stringify(selectedTicket) !== JSON.stringify(mounted.previousSelectedTicket)) {
      changeSelectedTicket(selectedTicket)

      mounted.previousSelectedTicket = selectedTicket;
    } else if (people !== mounted.previousPeople || JSON.stringify(activePromotion) !== JSON.stringify(mounted.previousActivePromotion)) {
      calculateTotalAmount();

      mounted.previousPeople = people;
      mounted.previousActivePromotion = activePromotion;
    } else if (hasError) {
      const y = document.querySelector('.error').getBoundingClientRect().top + window.scrollY - 50;
      window.scrollTo({ top: y, behavior: 'smooth' });

      setHasError(false);
    }
  }, [setSectorOptions, calculateTotalAmount, changeSelectedTicket, people, selectedTicket, activePromotion, ticketOptions, hasError, setHasError]);

  function checkPromoCode() {
    if (promotionCode !== '') {
      setSubmitting(true)
      request(CHECK_PROMOTION_CODE, { origin: 'ENTRANCE', code: promotionCode }).then(async (jsonObject) => {
        if (jsonObject != null && jsonObject.message != null) {
          clearTimeout(timer);

          setPromotionCodeError(jsonObject.message);
          timer = setTimeout(function () {
            setPromotionCodeError(null);
          }, 2000);
        } else {
          setActivePromotion(jsonObject);
        }
        setSubmitting(false)
      });
    } else {
      setPromotionCodeInputError(true);
      setTimeout(function () {
        setPromotionCodeInputError(false);
      }, 2000)
    }
  }

  function updateTicket(index, target) {
    const ticket = tickets[index];
    ticket[target.id] = target.value;

    const auxList = [...tickets.map((obj, i) => i === index ? ticket : obj)];
    setTickets(auxList);
  }

  function checkTickets() {
    let allValid = true;
    const auxTickets = [];
    for (let ticket of tickets) {
      allValid = isValidEntrance(ticket) && allValid;
      auxTickets.push({ ...ticket });
    }

    const validTermsCheckbox = document.getElementById('termsAndConditions').checked;
    setTermsAndConditionsError(!validTermsCheckbox);
    allValid = allValid && validTermsCheckbox;

    if (allValid) {
      setSubmitting(true);
      getEventId().then(eventId => {
        const body = {
          eventId: eventId,
          people: people,
          entrances: auxTickets
        }
        if (activePromotion != null) {
          body['codePromo'] = promotionCode;
        }

        request(REGISTER_PAYMENT, body).then(async (jsonObject) => {
          window.location.href = '/payment?id=' + jsonObject.id;
        });
      })
    } else {
      setTickets(auxTickets);
      setHasError(true);
    }
  }

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/public/billeterie.css" />
        </Helmet>
        <Loading isLoading={submitting} />

        <div id="tickets" className="row section ticketsSection">
          <div className="ticketsOverlay" />
          <div className="sectionContainer ticketsContainer">
            <div className="ticketsTitleContainer">
              <h2 className="ticketsTitle">
                <span className="ticketsSubTitle">{strings('billeterie.getTicket')}</span>
                {strings('menu.tickets')}
              </h2>
              <p className="subtitle">{strings('billeterie.billeterieSubtitle', { date: date })}
              </p>

              <p className="ticketsSvg">
                <svg width="71px" height="11px">
                  <path fillRule="evenodd"
                    d="M59.669,10.710 L49.164,3.306 L39.428,10.681 L29.714,3.322 L20.006,10.682 L10.295,3.322 L1.185,10.228 L-0.010,8.578 L10.295,0.765 L20.006,8.125 L29.714,0.765 L39.428,8.125 L49.122,0.781 L59.680,8.223 L69.858,1.192 L70.982,2.895 L59.669,10.710 Z">
                  </path>
                </svg>
              </p>

              {rates.map((rate, index) =>
                <p key={index} className="ticketRate" dangerouslySetInnerHTML={{ __html: rate }} />
              )}
            </div>

            <div className="ticketsPricesWrapContainer">
              <div className="ticketsPricesWrap">
                <div className="ticketsPricesContainer">
                  <h2 className="ticketName">{strings('billeterie.pricing')}</h2>

                  {ticketOptions.map((option, index) => {
                    let width;
                    if (option.length % 3 === 0) {
                      width = '33%';
                    } else if (option.length % 2 === 0) {
                      width = '50%';
                    }

                    if (width == null) {
                      if (ticketOptions.length === 1) {
                        width = '50%';
                      } else if (index < 3) {
                        width = '33.33%';
                      } else {
                        width = '50%';
                      }
                    }

                    if (ticketOptions.length === 1) {
                      return [<div key={option.people + '-0'} className="ticketOptionContainer" style={{ width: '25%' }} />,
                      <div key={option.people + '-1'} className="ticketOptionContainer" style={{ width: width }}>
                        <div className="ticketOption active" value={option.people}>
                          <p className="ticketOptionName">{option.name}</p>
                          <p className="ticketOptionPrice">
                            {option.originalPrice ?
                              [<DisplayPrice price={option.originalPrice} currency={option.currency} className='linethrough' />,
                              <span> </span>,
                              <i class="icon-arrow-right" />,
                              <span> </span>]
                              :
                              null
                            }
                            <DisplayPrice price={option.price} currency={option.currency} /></p>
                        </div>
                      </div>,
                      <div key={option.people + '-2'} className="ticketOptionContainer" style={{ width: '25%' }} />]
                    } else {
                      return <div key={option.people} className="ticketOptionContainer" style={{ width: width }}>
                        <div className={'ticketOption' + (selectedTicket.people === option.people ? ' active' : '')} value={option.people} onClick={() => changeSelectedTicket(option)}>
                          <p className="ticketOptionName" dangerouslySetInnerHTML={{ __html: option.name }} />
                          <p className="ticketOptionPrice"><DisplayPrice price={option.price} currency={option.currency} /></p>
                        </div>
                      </div>
                    }
                  })}

                  <div className="w100 disFlex">
                    <Counter id='people' value={people} minValue={minTickets} maxValue={MAX_TICKETS} onChange={changePeople} style={showCounter ? null : { visibility: 'hidden' }} />
                    <div className="promotionalCodeContainer">
                      <input type="text" name="promoCode" className={'promoCodeInput' + (promotionCodeInputError ? ' error' : '')} value={promotionCode} onChange={event => setPromotionCode(event.target.value)}
                        placeholder={strings('billeterie.promotionalCode')} />
                      <button onClick={() => checkPromoCode()} className={'promoCodeBtn'}>{strings('billeterie.check')}</button>
                      <p className="promotionCodeError">{strings('billeterie.' + promotionCodeError)}</p>
                    </div>
                  </div>
                  <div className="w100 disFlex">
                    <div className="totalAmountContainer">
                      <p className="totalAmount"><span className={'totalLabel'}>{strings('common.total')}: </span><DisplayPrice price={originalAmount} currency={currency} className={totalAmount != null ? ' oldPrice' : ''} /><span
                        id="promotionCodePrice" style={totalAmount != null ? null : { display: 'none' }}> &#8594; <DisplayPrice price={totalAmount} currency={currency} /></span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ticketsInfoWrapContainer">
              {tickets.map((ticket, index) => {
                return <EntranceForm key={index} title={strings('common.entrance') + ' ' + (index + 1)} ticket={ticket} updateTicket={(element) => updateTicket(index, element)} sectorOptions={sectorOptions} />
              })}
            </div>

            <div className="ticketsLinkContainer">
              <div className="ticketsLinkWrap">
                <div className={'checkboxContainer' + (termsAndConditionsError ? ' error' : '')}>
                  <input type="checkbox" id="termsAndConditions" />
                  <label htmlFor="termsAndConditions">{strings('billeterie.termsConditionsStart')} <span className="linkText cPointer" onClick={() => setOpenModal(true)}>{strings('billeterie.termsConditionsLink')}</span> {strings('billeterie.termsConditionsEnd')}</label>
                </div>
                <button onClick={() => checkTickets()} className={'ticketsBtn' + (submitting ? ' disabled' : '')}>{strings('billeterie.subscribe')}</button>
              </div>
            </div>
          </div>
        </div>

        <TermsAndConditions opened={openModal} setOpened={setOpenModal} />
      </div>
    </HelmetProvider >
  );
}
