import { strings } from "./Language";
import { isValidEmail, isValidPhone } from "../js/validator";
import '../css/entrance-form.css';

export const EMPTY_TICKET = {
  name: '',
  nameError: false,
  lastName: '',
  lastNameError: false,
  country: '',
  countryError: false,
  business: '',
  businessError: false,
  profesion: '',
  profesionError: false,
  email: '',
  emailError: false,
  phone: '',
  phoneError: false,
  businessSectorId: '',
  businessSectorIdError: false,
  businessSpecialityIds: [],
  businessSpecialityIdsError: false
}

export function isValidEntrance(ticket) {
  let allValid = true;
  for (let key of Object.keys(ticket)) {
    if (!key.endsWith('Error') && !key.endsWith('Field')) {
      let valid;
      if (key === 'email') {
        valid = ticket[key] != null && isValidEmail(ticket[key]);
      } else if (key === 'phone') {
        valid = ticket[key] != null && isValidPhone(ticket[key]);
        // } else if (key === 'businessSpecialityIds') {
        //   valid = ticket[key] != null && ticket[key].length > 0;
      } else {
        valid = ticket[key] != null && ticket[key] !== '';
      }

      ticket[key + 'Error'] = !valid;
      allValid = allValid && valid;
    }
  }
  return allValid;
}

export default function EntranceForm({ ticket, title, sectorOptions, updateTicket, disabled }) {

  return (
    <div className="ticketsInfoWrap">
      <div className="ticketsInfoContainer">
        <h2 className="ticketName">{title}</h2>

        <p className="label">{strings('billeterie.name')} *</p>
        <input type="text" id="name" className={'inputElement' + (disabled ? ' disabled' : '') + (ticket.nameError ? ' error' : '')} maxLength="150" defaultValue={ticket.name}
          onChange={event => updateTicket(event.target)} disabled={disabled} />
        <p className="label">{strings('billeterie.lastName')} *</p>
        <input type="text" id="lastName" className={'inputElement' + (disabled ? ' disabled' : '') + (ticket.lastNameError ? ' error' : '')} maxLength="150" defaultValue={ticket.lastName}
          onChange={event => updateTicket(event.target)} disabled={disabled} />
        <p className="label">{strings('common.country')} *</p>
        <input type="text" id="country" className={'inputElement' + (disabled ? ' disabled' : '') + (ticket.countryError ? ' error' : '')} maxLength="100" defaultValue={ticket.country}
          onChange={event => updateTicket(event.target)} disabled={disabled} />
        <p className="label">{strings('billeterie.business')} *</p>
        <input type="text" id="business" className={'inputElement' + (disabled ? ' disabled' : '') + (ticket.businessError ? ' error' : '')} maxLength="150" defaultValue={ticket.business}
          onChange={event => updateTicket(event.target)} disabled={disabled} />
        <p className="label">{strings('billeterie.function')} *</p>
        <input type="text" id="profesion" className={'inputElement' + (disabled ? ' disabled' : '') + (ticket.profesionError ? ' error' : '')} maxLength="150" defaultValue={ticket.profesion}
          onChange={event => updateTicket(event.target)} disabled={disabled} />
        <p className="label">{strings('billeterie.professionalEmail')} *</p>
        <input type="email" id="email" className={'inputElement' + (disabled ? ' disabled' : '') + (ticket.emailError ? ' error' : '')} maxLength="150" defaultValue={ticket.email}
          onChange={event => updateTicket(event.target)} disabled={disabled} />
        <p className="label">{strings('common.phone')} *</p>
        <input type="tel" id="phone" className={'inputElement' + (disabled ? ' disabled' : '') + (ticket.phoneError ? ' error' : '')} maxLength="150" defaultValue={ticket.phone}
          onChange={event => updateTicket(event.target)} disabled={disabled} />
        <p className="label">{strings('common.sector')} *</p>
        <select id="businessSectorId" className={'inputElement' + (disabled ? ' disabled' : '') + (ticket.businessSectorIdError ? ' error' : '')} defaultValue={ticket.businessSectorId}
          onChange={event => updateTicket(event.target)} disabled={disabled}>
          <option value="">{strings('common.select')}</option>
          {sectorOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
        </select>
      </div>
    </div>
  );
}

