import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const AVAILABLE_LANGUAGES = ['fr-FR', 'en-GB']
const DEFAULT_LANGUAGE = 'fr-FR'

let languageTexts = {}

function initLanguage() {
  let locale = localStorage['locale'];

  if (locale == null) {
    if (AVAILABLE_LANGUAGES.indexOf(navigator.language) >= 0) {
      locale = navigator.language;
    } else {
      locale = DEFAULT_LANGUAGE;
    }

    changeLanguage(locale)
  } else if (Object.keys(languageTexts).length === 0) {
    loadLanguage(locale);
  }
}

function loadLanguage(locale) {
  languageTexts = {
    ...require('../locale/' + locale + '.json'),
  }
}

export function getAvailableLanguages() {
  const languages = [];
  for (let language of AVAILABLE_LANGUAGES) {
    languages.push({
      label: strings('languages.' + language),
      value: language,
      code: strings('languagesCode.' + language)
    });
  }
  return languages;
}

export function getLocale() {
  let locale = localStorage['locale'];

  if (locale == null) {
    if (AVAILABLE_LANGUAGES.indexOf(navigator.language) >= 0) {
      locale = navigator.language;
    } else {
      locale = DEFAULT_LANGUAGE;
    }
  }

  return locale;
}

export function changeLanguage(newLocale, reload) {
  localStorage.setItem('locale', newLocale)
  if (reload)
    window.location.reload();
}

export function strings(labelKey, params = {}) {
  initLanguage();

  let label = { ...languageTexts };
  for (let key of labelKey.split('.')) {
    label = label[key]
  }

  for (let key of Object.keys(params)) {
    label = label.replace('{' + key + '}', params[key]);
  }

  return label
}

export const LanguageRedirect = () => {
  const [redirect, setRedirect] = useState();

  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/fr' || path.startsWith('/fr/')) {
      changeLanguage('fr-FR')
      setRedirect(path.replace('/fr', ''))
    } else if (path === '/en' || path.startsWith('/en/')) {
      changeLanguage('en-GB')
      setRedirect(path.replace('/en', ''))
    }
  }, [setRedirect]);

  return redirect != null ? <Navigate to={redirect} replace /> : null;
};