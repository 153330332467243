import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { request } from '../../js/APIUtils.js';
import { SCANNED_ENTRANCE_LIST_FILTERS, EVENT_YEARS, GET_SCANNED_ENTRANCE_LIST } from '../../js/endpoints.js';
import Pagination from '../../components/Pagination.js'
import { strings } from '../../components/Language.js';
import { formatDate } from '../../js/Common.js';
import CheckBox from '../../components/inputs/CheckBox.js';
import Multiselect from '../../components/inputs/Multiselect.js';
import Loading from '../../components/Loading.js';
import { useParamsContext } from '../../js/ParamsContext.js';

const NUM_ROWS = 15;
const emptyFilter = {
  eventId: null,
  immoTechEntranceName: '',
  immoTechUserIds: [],
  businessName: '',
  date: null,
  fromHour: null,
  toHour: null,
  hideRepeated: false
}
const doneTypingInterval = 500;
let typingTimer;

export default function Visited() {
  const { setParams } = useParamsContext();
  const [loading, setLoading] = useState(true);
  const [years, setYears] = useState([])
  const [elementList, setElementList] = useState([]);
  const [filter, setFilter] = useState(emptyFilter);
  const [resetPagination, setResetPagination] = useState(false);
  const [dateList, setDateList] = useState([]);
  const [originalHourList, setOriginalHourList] = useState([]);
  const [fromHourList, setFromHourList] = useState([]);
  const [toHourList, setToHourList] = useState([]);
  const [userList, setUserList] = useState([]);

  const changeFilter = useCallback((field, newValue) => {
    const newFilter = { ...filter };
    newFilter[field] = newValue
    setFilter(newFilter);
    if (field === 'immoTechEntranceName' || field === 'businessName') {
      clearTimeout(typingTimer);

      typingTimer = setTimeout(function () {
        setResetPagination(true);
      }, doneTypingInterval);
    } else {
      setResetPagination(true);

      if (field === 'fromHour') {
        if (isNaN(newValue) || newValue === '') {
          setToHourList(originalHourList);
        } else {
          const fromHour = Number(newValue);
          setToHourList(originalHourList.filter(h => Number(h.value) >= fromHour));
        }
      } else if (field === 'toHour') {
        if (isNaN(newValue) || newValue === '') {
          setFromHourList(originalHourList);
        } else {
          const toHour = Number(newValue);
          setFromHourList(originalHourList.filter(h => Number(h.value) <= toHour));
        }
      }
    }
  }, [filter, originalHourList]);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      setParams({ showMenuBar: true });
      request(EVENT_YEARS).then(jsonObject => {
        setYears(jsonObject.list)

        const defaultEvent = jsonObject.list.filter(y => y.label === new Date().getFullYear().toString());
        let eventId;
        if (defaultEvent.length === 1) {
          eventId = defaultEvent[0].value;
        } else {
          eventId = jsonObject.list[jsonObject.list.length - 1].value;
        }

        request(SCANNED_ENTRANCE_LIST_FILTERS, { eventId: eventId }).then(jsonObject => {
          changeFilter('eventId', eventId)
          setDateList(jsonObject.dateList);
          setOriginalHourList(jsonObject.hourList);
          setFromHourList(jsonObject.hourList);
          setToHourList(jsonObject.hourList);
          setUserList(jsonObject.userList);
        })
      })

      mounted.current = true;
    }
  }, [filter, changeFilter, setParams]);

  async function loadPage(page) {
    if (filter.eventId != null) {
      setLoading(true);
      setResetPagination(false);

      const body = {
        page: page,
        pageSize: NUM_ROWS,
        ...filter,
        eventId: filter.eventId
      }

      return request(GET_SCANNED_ENTRANCE_LIST, body).then(jsonObject => {
        setElementList(jsonObject.elements);
        setLoading(false);
        return jsonObject;
      })
    }
  }

  return (
    <HelmetProvider>
      <div className='menuMarg'>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/logged/visited.css" />
          <link rel="stylesheet" type="text/css" href="/css/table.css" />
        </Helmet>
        <Loading isLoading={loading} />

        <div className='maxW1600'>

          <div style={{ display: 'none' }}>
            <Multiselect id="eventId" options={years} value={filter.eventId} className={'inputElement'} multiple={false}
              onChange={newValue => changeFilter('eventId', newValue)} label={strings('dashboard.event')} />
          </div>

          <div className="filterRow">
            <div className='filterField'>
              {filter.immoTechEntranceName !== '' ?
                <label className='inputLabel'>{strings('billeterie.name')}</label>
                :
                null
              }
              <input type="text" value={filter.immoTechEntranceName} onChange={event => changeFilter('immoTechEntranceName', event.target.value)}
                placeholder={strings('billeterie.name')} />
            </div>
            <div className='filterField'>
              <Multiselect id='scannedUser' options={userList} value={filter.immoTechUserIds} onChange={newValue => changeFilter('immoTechUserIds', newValue)}
                multiple={true} label={strings('visited.scannedUser')} />
            </div>
            <div className='filterField'>
              {filter.businessName !== '' ?
                <label className='inputLabel'>{strings('billeterie.business')}</label>
                :
                null
              }
              <input type="text" value={filter.businessName} onChange={event => changeFilter('businessName', event.target.value)}
                placeholder={strings('billeterie.business')} />
            </div>
            <div className='filterField'>
              <Multiselect id='date' options={dateList} value={filter.date} onChange={newValue => changeFilter('date', newValue)}
                multiple={false} label={strings('visited.scanDate')} showAll={true} />
            </div>
            <div className='filterField'>
              <Multiselect id='fromHour' options={fromHourList} value={filter.fromHour} onChange={newValue => changeFilter('fromHour', newValue)}
                multiple={false} label={strings('visited.fromScanHour')} showAll={true} />
            </div>
            <div className='filterField'>
              <Multiselect id='toHour' options={toHourList} value={filter.toHour} onChange={newValue => changeFilter('toHour', newValue)}
                multiple={false} label={strings('visited.toScanHour')} showAll={true} />
            </div>
            <div className="filterField">
              <CheckBox label={strings('visited.hideRepeated')} value={filter.hideRepeated} onChange={newValue => changeFilter('hideRepeated', newValue)} />
            </div>
          </div>

          <div className='tableScroll'>
            <table className="colorTable darkBlueTable">
              <thead>
                <tr>
                  <th>
                    {strings('billeterie.name')}
                  </th>
                  <th>
                    {strings('billeterie.lastName')}
                  </th>
                  <th>
                    {strings('common.country')}
                  </th>
                  <th>
                    {strings('billeterie.business')}
                  </th>
                  <th>
                    {strings('billeterie.function')}
                  </th>
                  <th>
                    {strings('billeterie.professionalEmail')}
                  </th>
                  <th>
                    {strings('common.phone')}
                  </th>
                  <th>
                    {strings('common.sector')}
                  </th>
                  <th>
                    {strings('visited.scannedUser')}
                  </th>
                  <th>
                    {strings('visited.scanDate')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {elementList.length === 0 ?
                  <tr><td colSpan={11}>{strings('visited.noData')}</td></tr>
                  :
                  null}
                {elementList.map((element, index) =>
                  <tr key={index}>
                    <td>{element.entrance.name}</td>
                    <td>{element.entrance.lastName}</td>
                    <td>{element.entrance.country}</td>
                    <td>{element.entrance.business}</td>
                    <td>{element.entrance.profesion}</td>
                    <td>{element.entrance.email}</td>
                    <td>{element.entrance.phone}</td>
                    <td>{element.entrance.businessSectorName}</td>
                    <td>{element.user.name != null ? element.user.name + ' ' + element.user.lastName : element.user.email}</td>
                    <td>{formatDate(element.dateCreated)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Pagination resetPagination={resetPagination}
            loadPage={loadPage}
            pageSize={NUM_ROWS} />
        </div>
      </div>
    </HelmetProvider >
  );
}
