import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { request } from '../../js/APIUtils.js';
import { ENTRANCE_LIST_FILTERS, EVENT_YEARS, GET_ENTRANCE_LIST, RESEND_ENTRANCE } from '../../js/endpoints.js';
import Pagination from '../../components/Pagination.js'
import { strings } from '../../components/Language.js';
import CheckBox from '../../components/inputs/CheckBox.js';
import Multiselect from '../../components/inputs/Multiselect.js';
import Loading from '../../components/Loading.js';
import { useParamsContext } from '../../js/ParamsContext.js';
import DisplayPrice from '../../components/DisplayPrice.js';
import { formatDate, setTooltipsPosition } from '../../js/Common.js';
import Popup from '../../components/Popup.js';
import { isValidEmail } from '../../js/validator.js';
import PdfPreview from '../../components/PDFViewer.js';

const NUM_ROWS = 10;
const emptyFilter = {
  eventId: null,
  type: null,
  exposantId: null,
  name: '',
  status: null,
  onlyPaying: false
}
const doneTypingInterval = 500;
let typingTimer;

const COLUMNS = [strings('entrances.paymentId'), strings('entrances.originalAmount'), strings('entrances.amount'), strings('entrances.status'), strings('entrances.dateCreated'), strings('entrances.paymentDate'),
strings('entrances.paymentCode'), strings('entrances.entranceId'), strings('entrances.workshop'), strings('billeterie.name'),
strings('billeterie.lastName'), strings('billeterie.business'), strings('billeterie.function'), strings('billeterie.professionalEmail'),
strings('common.phone'), strings('common.sector'), strings('entrances.discountCode'), strings('visited.vip'), strings('entrances.entrance'), strings('entrances.send')]
export default function Entrances() {
  const { setParams } = useParamsContext();
  const [loading, setLoading] = useState(true);
  const [years, setYears] = useState([])
  const [elementList, setElementList] = useState([]);
  const [filter, setFilter] = useState(emptyFilter);
  const [resetPagination, setResetPagination] = useState(false);
  const [entranceTypeList, setEntranceTypeList] = useState([]);
  const [exposantList, setExposantList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const [selectedEntrance, setSelectedEntrance] = useState(null);
  const [sendEmail, setSendEmail] = useState('');
  const [sendEmailError, setSendEmailError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [sentSuccess, setSentSuccess] = useState(false);

  const [openPdfPreview, setOpenPdfPreview] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  const changeFilter = useCallback((field, newValue) => {
    const newFilter = { ...filter };
    newFilter[field] = newValue
    setFilter(newFilter);
    if (field === 'name') {
      clearTimeout(typingTimer);

      typingTimer = setTimeout(function () {
        setResetPagination(true);
      }, doneTypingInterval);
    } else {
      setResetPagination(true);
    }
  }, [filter]);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      setParams({ showMenuBar: true });
      request(EVENT_YEARS).then(jsonObject => {
        setYears(jsonObject.list)

        const defaultEvent = jsonObject.list.filter(y => y.label === new Date().getFullYear().toString());
        let eventId;
        if (defaultEvent.length === 1) {
          eventId = defaultEvent[0].value;
        } else {
          eventId = jsonObject.list[jsonObject.list.length - 1].value;
        }

        request(ENTRANCE_LIST_FILTERS, { eventId: eventId }).then(jsonObject => {
          changeFilter('eventId', eventId)
          setEntranceTypeList(jsonObject.entranceTypeList);
          setExposantList(jsonObject.exposantList);
          setStatusList(jsonObject.statusList);
        })
      })

      mounted.current = true;
    } else if (JSON.stringify(elementList) !== JSON.stringify(mounted.elementList)) {
      setTooltipsPosition();
      mounted.elementList = elementList;
    }
  }, [filter, changeFilter, setParams, elementList]);

  async function loadPage(page) {
    if (filter.eventId != null) {
      setLoading(true);
      setResetPagination(false);

      const body = {
        page: page,
        pageSize: NUM_ROWS,
        ...filter
      }

      return request(GET_ENTRANCE_LIST, body).then(jsonObject => {
        setElementList(jsonObject.elements);
        setLoading(false);
        return jsonObject;
      })
    }
  }

  function openResendPopup(entrance) {
    setSelectedEntrance(entrance);
    setSendEmail(entrance.email);
    setOpenEmailPopup(true);
    setSubmitting(false);
    setSentSuccess(false);
  }

  function resendEmail() {
    if (isValidEmail(sendEmail)) {
      setSendEmailError(false);
      setSubmitting(true);
      request(RESEND_ENTRANCE, { entranceId: selectedEntrance.id, email: sendEmail }).then(() => {
        setSentSuccess(true);
      })
    } else {
      setSendEmailError(true);
    }
  }

  function previewPdf(entrance) {
    setPdfUrl(entrance.entranceUrl);
    setOpenPdfPreview(true);
  }

  return (
    <HelmetProvider>
      <div className='menuMarg'>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="/css/logged/entrances.css" />
          <link rel="stylesheet" type="text/css" href="/css/table.css" />
        </Helmet>
        <Loading isLoading={loading} />

        <div className='maxW1600'>

          <Multiselect id="eventId" options={years} value={filter.eventId} className='selectEvent' multiple={false}
            onChange={newValue => changeFilter('eventId', newValue)} label={strings('dashboard.event')} />

          <div className="filterRow">
            <div className='filterField'>
              <Multiselect id="type" options={entranceTypeList} value={filter.type} multiple={false} showAll={true}
                onChange={newValue => changeFilter('type', newValue)} label={strings('entrances.type')} />
            </div>
            <div className='filterField'>
              <Multiselect id="exposantId" options={exposantList} value={filter.exposantId} multiple={false} showAll={true}
                onChange={newValue => changeFilter('exposantId', newValue)} label={strings('entrances.exposant')} />
            </div>
            <div className='filterField'>
              {filter.name !== '' ?
                <label className='inputLabel'>{strings('entrances.name')}</label>
                :
                null
              }
              <input type="text" value={filter.name} onChange={event => changeFilter('name', event.target.value)}
                placeholder={strings('entrances.name')} />
            </div>
            <div className='filterField'>
              <Multiselect id='status' options={statusList} value={filter.status} onChange={newValue => changeFilter('status', newValue)}
                multiple={false} label={strings('entrances.status')} showAll={true} />
            </div>
            <div className="filterField">
              <CheckBox label={strings('entrances.onlyPaying')} value={filter.onlyPaying} onChange={newValue => changeFilter('onlyPaying', newValue)} />
            </div>
          </div>

          <div className='tableScroll'>
            <table className="colorTable darkBlueTable">
              <thead>
                <tr>
                  {COLUMNS.map(column =>
                    <th key={column}>
                      {column}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {elementList.length === 0 ?
                  <tr><td colSpan={COLUMNS.length}>{strings('visited.noData')}</td></tr>
                  :
                  null}
                {elementList.map(payment =>
                  payment.entrances.map((entrance, index) =>
                    <tr key={entrance.id}>
                      {index === 0 ?
                        [<td key={payment.id} rowSpan={payment.entrances.length}>{payment.id}</td>,
                        <td key={payment.id + '_1'} rowSpan={payment.entrances.length}><DisplayPrice price={payment.originalAmount} currency={payment.currency} /></td>,
                        <td key={payment.id + '_2'} rowSpan={payment.entrances.length}><DisplayPrice price={payment.amount} currency={payment.currency} /></td>,
                        <td key={payment.id + '_3'} rowSpan={payment.entrances.length}>{payment.status}</td>,
                        <td key={payment.id + '_4'} rowSpan={payment.entrances.length}>{formatDate(payment.dateCreated, 'DD/MM/yyyy HH:mm')}</td>,
                        <td key={payment.id + '_5'} rowSpan={payment.entrances.length}>{payment.datePaid ? formatDate(payment.datePaid, 'DD/MM/yyyy HH:mm') : ''}</td>,
                        <td key={payment.id + '_6'} rowSpan={payment.entrances.length}>{payment.code}</td>]
                        : null
                      }
                      <td>{entrance.id}</td>
                      <td>{entrance.workshops ? entrance.workshops[0].name : ''}</td>
                      <td>{entrance.name}</td>
                      <td>{entrance.lastName}</td>
                      <td>{entrance.business}</td>
                      <td>{entrance.profesion}</td>
                      <td>{entrance.email}</td>
                      <td>{entrance.phone}</td>
                      <td>{entrance.businessSectorName}</td>
                      <td>{entrance.promotionCode}</td>
                      <td>{entrance.vip}</td>
                      <td>
                        {entrance.entranceUrl ?
                          <div className="tooltip">
                            <button onClick={() => previewPdf(entrance)} className='actionBtn blue fSize20'><i className='icon-eye' /></button>
                            <div className="tooltiptext">
                              {strings('entrances.viewEntrance')}
                            </div>
                          </div>
                          : null}</td>
                      <td>{payment.paid ?
                        <div className="tooltip">
                          <button onClick={() => openResendPopup(entrance)} className='actionBtn green fSize20'><i className='icon-email' /></button>
                          <div className="tooltiptext">
                            {strings('entrances.resendEntrance')}
                          </div>
                        </div>
                        : null}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <Pagination resetPagination={resetPagination}
            loadPage={loadPage}
            pageSize={NUM_ROWS} />
        </div>
      </div>

      <Popup id='emailPopup' opened={openEmailPopup} setOpened={setOpenEmailPopup}>
        <h2 className='centered blue'>{strings('entrances.resendEmail')}</h2>
        {sentSuccess ?
          <div className='centered'>
            <span className="alert alert-success centered">
              <i className="icon-checked" /> {strings('entrances.sentSuccessfully')}
            </span>
          </div>
          :
          <div className='centered'>
            {sendEmail !== '' ?
              <label className='inputLabel'>{strings('common.email')}</label>
              :
              null
            }
            <input type="text" value={sendEmail} className={'emailInput' + (sendEmailError ? 'error' : '')} onChange={event => setSendEmail(event.target.value)}
              placeholder={strings('common.email')} />
          </div>
        }

        {sentSuccess ?
          <div className="ticketsInfoContainer noMargTop">
            <button onClick={() => setOpenEmailPopup(false)} className='button'>{strings('common.close')}</button>
          </div>
          :
          <div className="ticketsInfoContainer noMargTop">
            <button onClick={() => resendEmail()} className={'button' + (submitting ? ' disabled' : '')} disabled={submitting}>{strings('entrances.send')}</button>
          </div>
        }
      </Popup>
      <PdfPreview opened={openPdfPreview} setOpened={setOpenPdfPreview} pdfUrl={pdfUrl} />
    </HelmetProvider >
  );
}
