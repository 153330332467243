import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import '../css/header.css';
import '../css/footer.css';
import { changeLanguage, getAvailableLanguages, getLocale, strings } from '../components/Language';
import { isAuthenticated } from '../js/APIUtils';
import { useParamsContext } from '../js/ParamsContext';
import { ROLE } from '../js/Common';

export default function Layout() {
  const [menuOpened, setMenuOpened] = useState(false);
  const [subMenuOpened, setSubMenuOpened] = useState(null);
  const [activeSticky] = useState(useLocation().pathname === '/');
  const [sticky, setSticky] = useState(false);
  const [year] = useState(new Date().getFullYear());
  const [userAuthenticated, setUserAuthenticated] = useState(null);
  const { params } = useParamsContext();

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      document.title = 'ImmoTech – Le 1er Salon de la Proptech au Maroc';
      isAuthenticated().then(response => {
        setUserAuthenticated(response);
      });

      mounted.current = true;
    }
  }, []);

  const checkSticky = () => {
    setSticky(window.innerHeight < window.scrollY);
  };

  window.addEventListener('scroll', checkSticky);

  function closeMenus() {
    setMenuOpened(false);
    setSubMenuOpened(null);
  }

  function toggleMenu(menu) {
    if (subMenuOpened === menu) {
      setSubMenuOpened(null);
    } else {
      setSubMenuOpened(menu);
    }
  }

  function selectLanguage(newLocale) {
    changeLanguage(newLocale, true);
    closeMenus();
  }

  return (
    <div>
      <header className={(activeSticky && sticky) || params.stickyMenuBar ? 'sticky' : '' + (params.showMenuBar ? ' visibleMenuBar' : '')}>
        <div className="headerWrap">
          <div className="col-lg-3 col-6">
            <a className="logo" href="/#headBlock">
              <img width="230" height="46" src={require('../logo-immotech.png')} alt="ImmoTech" />
            </a>
          </div>
          <div className="col-lg-9">
            <nav className="navbar">
              <button className="navbar-toggler" type="button" onClick={() => setMenuOpened(!menuOpened)}>
                <span className="navbar-toggler-icon"><i className="icon icon-menu"></i></span>
              </button>

              <div className={'navbar-collapse' + (menuOpened ? ' open' : ' closed')}>
                <ul className="navbar-nav main-menu">
                  <li id="menu-item" className="menu-item dropdown" onClick={() => toggleMenu('immotech')}>
                    <button className="nav-link dropdown-toggle" data-toggle="dropdown">{strings('menu.immotech')}</button>
                    <ul className={'dropdown-menu' + (subMenuOpened === 'immotech' ? ' open' : ' closed')}>
                      { /* <li id="menu-item" className="menu-item">
                        <a href="/#programme" className="dropdown-item" onClick={closeMenus}>{strings('menu.schedule')}</a>
                      </li>*/}
                      <li id="menu-item" className="menu-item">
                        <a href="/#info" className="dropdown-item" onClick={closeMenus}>{strings('menu.info')}</a>
                      </li>
                      <li id="menu-item" className="menu-item">
                        <a href="/#partners" className="dropdown-item" onClick={closeMenus}>{strings('menu.partners')}</a>
                      </li>
                      <li id="menu-item" className="menu-item">
                        <a href="/#contact" className="dropdown-item" onClick={closeMenus}>{strings('menu.contact')}</a>
                      </li>
                      <li id="menu-item" className="menu-item">
                        <a href="/competition" className="dropdown-item" onClick={closeMenus}>{strings('menu.competition')}</a>
                      </li>
                    </ul>
                    <span className="dropdown-menu-toggle" />
                  </li>
                  <li id="menu-item" className="menu-item dropdown" onClick={() => toggleMenu('tickets')}>
                    <button className="nav-link dropdown-toggle" data-toggle="dropdown">{strings('menu.tickets')}</button>
                    <ul className={'dropdown-menu' + (subMenuOpened === 'tickets' ? ' open' : ' closed')}>
                      <li id="menu-item" className="menu-item">
                        <a href="/billeterie" className="dropdown-item" onClick={closeMenus}>{strings('menu.entrances')}</a>
                      </li>
                      <li id="menu-item" className="menu-item">
                        <a href="/workshops" className="dropdown-item" onClick={closeMenus}>{strings('menu.workshops')}</a>
                      </li>
                    </ul>
                    <span className="dropdown-menu-toggle" />
                  </li>
                  <li className="menu-item">
                    <a href="/exposant" className="nav-link" onClick={closeMenus}>{strings('menu.exposant')}</a>
                  </li>
                  <li id="menu-item" className="menu-item dropdown" onClick={() => toggleMenu('gallery')}>
                    <button className="nav-link dropdown-toggle" data-toggle="dropdown">{strings('menu.gallery')}</button>
                    <ul className={'dropdown-menu' + (subMenuOpened === 'gallery' ? ' open' : ' closed')}>
                      <li id="menu-item" className="menu-item">
                        <a href="/images" className="dropdown-item" onClick={closeMenus}>{strings('menu.images')}</a>
                      </li>
                      <li id="menu-item" className="menu-item">
                        <a href="/videos" className="dropdown-item" onClick={closeMenus}>{strings('menu.videos')}</a>
                      </li>
                    </ul>
                    <span className="dropdown-menu-toggle" />
                  </li>
                  {getAvailableLanguages().length > 1 ?
                    <li id="menu-item" className="menu-item dropdown" onClick={() => toggleMenu('languages')}>
                      <button className="nav-link dropdown-toggle" data-toggle="dropdown">{strings('languagesCode.' + getLocale())}</button>
                      <ul className={'dropdown-menu last' + (subMenuOpened === 'languages' ? ' open' : ' closed')}>
                        {getAvailableLanguages().map(language =>
                          getLocale() !== language.value ?
                            <li id="menu-item" className="menu-item" key={language.value}>
                              <button className="dropdown-item" onClick={() => selectLanguage(language.value)}>{language.label}</button>
                            </li>
                            :
                            null
                        )}
                      </ul>
                      <span className="dropdown-menu-toggle" />
                    </li>
                    :
                    null
                  }
                  {userAuthenticated?.id ?
                    <li id="menu-item-2650" className="menu-item dropdown" onClick={() => toggleMenu('userArea')}>
                      <button className="nav-link dropdown-toggle" data-toggle="dropdown">{strings('menu.userArea')}</button>
                      <ul className={'dropdown-menu last' + (subMenuOpened === 'userArea' ? ' open' : ' closed')}>
                        <li id="menu-item-2092" className="menu-item">
                          <a href="/dashboard" className="dropdown-item" onClick={closeMenus}>{strings('menu.dashboard')}</a>
                        </li>
                        <li id="menu-item-2092" className="menu-item">
                          <a href="/visited" className="dropdown-item" onClick={closeMenus}>{strings('menu.visited')}</a>
                        </li>
                        {userAuthenticated.role === ROLE.Developer ?
                          [<li key={'emails'} id="menu-item-2092" className="menu-item">
                            <a href="/emails" className="dropdown-item" onClick={closeMenus}>{strings('menu.emails')}</a>
                          </li>,
                          <li key={'exposants'} id="menu-item-2092" className="menu-item">
                            <a href="/exposants" className="dropdown-item" onClick={closeMenus}>{strings('menu.exposants')}</a>
                          </li>,
                          <li key={'entrances'} id="menu-item-2092" className="menu-item">
                            <a href="/entrances" className="dropdown-item" onClick={closeMenus}>{strings('menu.entranceList')}</a>
                          </li>]
                          :
                          null
                        }
                        <li id="menu-item-2092" className="menu-item">
                          <a href="/logout" className="dropdown-item" onClick={closeMenus}>{strings('menu.logout')}</a>
                        </li>
                      </ul>
                      <span className="dropdown-menu-toggle" />
                    </li>
                    :
                    <li className="menu-item">
                      <a href="/login" className="nav-link" onClick={closeMenus}>{strings('login.title')}</a>
                    </li>
                  }
                </ul>
              </div>
            </nav>
          </div>
        </div>

        <button className="BackTo" style={sticky ? null : { display: 'none' }} onClick={() => window.scrollTo(0, 0)}>
          <span className="icon icon-chevron-up" />
        </button>
      </header>

      <Outlet />

      <footer>
        <div className="container">
          <div className="sectionRow">
            <div className="col-lg-12">
              <div className="ts-footer-social centered">
                <ul>
                  <li className="ts-linkedin">
                    <a href="https://www.linkedin.com/company/immotech-mubawab/" target="_blank"
                      rel="noreferrer">
                      <i className="icon fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="copyright-text centered">
                <p>
                  © {year}&nbsp;{strings('footer.copyright')}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}